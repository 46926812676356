<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
    :showRating="false"
  >
    <div class="columns is-mobile" v-for="item in form.items" :key="item.lookupId">
      <div class="column is-4">
        <div class="label has-text-right mt-4">{{ lookupName(item.lookupId) }}</div>
      </div>
      <div class="column is-1">
        <img :src="lookupIcon(item)" />
      </div>
      <div class="column mt-3">
        <enum-drop-down v-model="item.assistanceLevel" :data="selectLookup" smallWidth :disabled="readonly" />
      </div>
      <div class="column mt-4">
        <b-checkbox v-if="item.assistanceLevel !== na" v-model="item.consent" :disabled="readonly">I consent to this assistance</b-checkbox>
      </div>
    </div>
    <p class="subtitle mt-6 has-text-centered">
      I have had this form explained to me by <b>{{ form.createdBy }}</b> on <b>{{ shortFormat(form.assessmentDate) }}</b
      ><br />
      I consent to getting all the help / supervision I need from staff with all the care needs I have as indicated above.<br />
      Where I can, I will do these for myself.
    </p>

    <CardComponent title="My Individual Support Plan">
      <NotesField label="Rationale" v-model="form.rationale" placeholder="Rationale for support" :disabled="readonly" />
      <NotesField label="Documentation" v-model="form.documentation" placeholder="Documentation supporting plan" :disabled="readonly" />
      <NotesField label="Supports In Place" v-model="form.inPlace" placeholder="Supports in place" :disabled="readonly" />
      <NotesField label="Support Required" v-model="form.required" placeholder="Support required" :disabled="readonly" />
    </CardComponent>
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import EnumDropDown from "@/components/EnumDropDown";

import AssessmentType from "@/enums/assessmentType";
import assessmentMixin from "@/mixins/assessmentMixin";
import DatesMixin from "@/mixins/datesMixin.js";

import lookups from "@/enums/lookups.js";
import intimateCare from "@/enums/intimateCare.js";
import CardComponent from "@/components/CardComponent.vue";
import NotesField from "@/components/NotesField.vue";

export default {
  components: { AssessmentForm, EnumDropDown, CardComponent, NotesField },
  mixins: [assessmentMixin, DatesMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.IntimateCare,
      lookupType: lookups.Enum.IntimateCare,
      selectLookup: intimateCare.SelectLookup,
      na: intimateCare.Enum.NA,
      form: this.getClearFormObject()
    };
  },

  computed: {
    initimateCareItems() {
      var values = this.$store.getters["lookups/getLookups"]({ lookupType: this.lookupType, gender: this.form.gender });
      if ((!values || !values.length) && !this.fetchingData) {
        this.getData();
      }
      return values;
    }
  },
  watch: {
    initimateCareItems: "initItems"
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      await this.$store.dispatch("lookups/getLookups", { lookupType: this.lookupType });
      this.fetchingData = false;
    },
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: this.residentId,
        assessmentDate: new Date(),
        gender: null,
        age: null,
        createdBy: this.$store.getters.userName,

        items: this.getClearFormItems(),

        rationale: null,
        documentation: null,
        inPlace: null,
        required: null
      };
    },
    initItems() {
      if (!this.form.items.length) this.form.items = this.getClearFormItems();
      this.setNotDirty();
    },
    getClearFormItems() {
      return this.initimateCareItems ? this.initimateCareItems.map(i => ({ lookupId: i.lookupId, icon: i.icon })) : [];
    },
    lookupName(id) {
      return this.initimateCareItems.find(i => i.lookupId === id)?.name ?? "";
    },
    lookupIcon(item) {
      var icon = item.icon ?? this.initimateCareItems.find(i => i.lookupId === item.lookupId)?.icon ?? "";
      return icon ? require("../../assets/intimateCare/" + icon) : "";
    }
  }
};
</script>
